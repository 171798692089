<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

       <el-col :span="4">
         <el-input
          v-model="query.count"
          placeholder="跳电次数"
          :maxLength="50"
          clearable
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div></div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="电池编号" prop="batteryMacId"></el-table-column>
      <el-table-column label="断电次数" prop="count"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getBadBattery } from "@/api/battery";
import moment from "moment";
export default {
  data() {
    let startTime = moment()
      .startOf("day")
      .subtract(1, "month")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      query: {
        count:3,
        startTime: startTime,
        endTime: endTime
      },
      dataSource: [],
    };
  },
  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        this.dateRange = [this.query.startTime, this.query.endTime];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },

    getList(current) {
     
      getBadBattery({ ...this.query}).then((res) => {
        this.dataSource = res.data.data;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
